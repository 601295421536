<template>
  <div class="start-window">
    <div v-if="!activated" class="b21">{{ $t('AUTH_ACTIVATE_ACTIVATING') }}</div>
    <div v-if="activated">
      <p>{{ $t('AUTH_ACTIVATE_ACTIVATED') }}</p>
      <p>
        {{ $t('AUTH_ACTIVATE_NO_REDIRECT') }}
        <router-link to="/auth/login">{{ $t('AUTH_COMMON_SIGN_IN') }}</router-link>
      </p>
    </div>
  </div>
</template>
<script>
import { AUTH_CHANGEPASS, AUTH_ACTIVATE } from "@/store/actions/auth";
import { mapGetters } from "vuex";
export default {
  name: "Login",
  props: ["activateCode"],
  computed: {
    ...mapGetters(["authStatus"]),
  },

  data() {
    return {
      activated: false,
    };
  },
  mounted() {
    this.activate();
  },
  methods: {
    activate() {
      this.$store
        .dispatch(AUTH_ACTIVATE, {
          activateCode: this.activateCode,
        })
        .then((res) => {
          this.activated = true;
          setTimeout(() => {
            this.$router.push("/auth/login");
          }, 5000);
        });
    },
  },
};
</script>


